import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Video_Overlays/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/MultimediaPerm';
import VideoOverlayTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video_Overlays/videoOverlayTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Video Overlays",
  "path": "/Web_User_Interface/1440p_Series/Multimedia/Video_Overlays/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Show or hide your camera´s name and time stamp.",
  "image": "./WebUI_1440p_SearchThumb_Multimedia_Overlays.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Multimedia_Overlays.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Multimedia Menu // Video Overlays' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Show or hide your camera´s name and time stamp.' image='/images/Search/WebUI_1440p_SearchThumb_Multimedia_Overlays.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Multimedia_Overlays.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Multimedia/Video_Overlays/' locationFR='/fr/Web_User_Interface/1440p_Series/Multimedia/Video_Overlays/' crumbLabel="Video Overlays" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cac7e70e0c304f2ac48420ecc75667a7/aa61c/1440p_Settings_Multimedia_Overlays.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACDklEQVQoz2XST2vTcBzH8T4VkXZt0+nqVLY2TZt/vzRt0q1pmm5Nt5VN5x/QyZCttynixYNM1FsrO8g2ENlhoHhQBD3pRfAB+FDempQyxcOH3+3Fmy+/hCRJZLNZJClH6vw5rN42vb1DOoMhncEIf2cYr707IhiMxu/ukP7DI+zefeT5Avn8FbLZHLncBRJnoEQymUItF1n2G7QX6/E816LpCOqigm0oOJaKbZap6jILtoup2miajloRFAsKiUwmE4PRkskkum4Q9lZoB0t4LZ+m14pfu+ZgWlWqdh0hLEzXp9tZIVgUaGoFpaQxPyePwUnlGNQJw5BOp4Pv+zSbTTzPw6nXsISBECaGZaK7HfprHhurZbTKGCwWSmeFEZpKpWKwG4YEQRBDEdiKwEUf4fpUrTqeruGKBrfXffqBilIqY2oVSsW/Ciegqqr/lLVaLRYaDvW1HVqDIf3+Ds/NKo80i/2tOzy4towsFxC6SmFeJjG5X7R0Oo2Uk5i9NIumGqjRNBNhWuzt3mP/yWOOT044fXPA65fPeH8w4unNEEMtUVbU8Q0ndVFptKmpKXK5aSqKgW0YbAYOr/Zu8OvzIT++fuTbp3d8OT3m+8kRPz+85cXdJdYDQVnRkYvK/4WZTDqGZy7mUeZmqSlXWapVuL7ssb11i167iWvbdB2bzdUuDWUOTb7MzEye6T//8DcfRFVsZmZpIwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cac7e70e0c304f2ac48420ecc75667a7/e4706/1440p_Settings_Multimedia_Overlays.avif 230w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/d1af7/1440p_Settings_Multimedia_Overlays.avif 460w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/7f308/1440p_Settings_Multimedia_Overlays.avif 920w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/2cb71/1440p_Settings_Multimedia_Overlays.avif 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cac7e70e0c304f2ac48420ecc75667a7/a0b58/1440p_Settings_Multimedia_Overlays.webp 230w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/bc10c/1440p_Settings_Multimedia_Overlays.webp 460w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/966d8/1440p_Settings_Multimedia_Overlays.webp 920w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/fa9eb/1440p_Settings_Multimedia_Overlays.webp 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cac7e70e0c304f2ac48420ecc75667a7/81c8e/1440p_Settings_Multimedia_Overlays.png 230w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/08a84/1440p_Settings_Multimedia_Overlays.png 460w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/c0255/1440p_Settings_Multimedia_Overlays.png 920w", "/en/static/cac7e70e0c304f2ac48420ecc75667a7/aa61c/1440p_Settings_Multimedia_Overlays.png 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cac7e70e0c304f2ac48420ecc75667a7/c0255/1440p_Settings_Multimedia_Overlays.png",
              "alt": "Web User Interface - 1440p Series - Multimedia Video Overlays",
              "title": "Web User Interface - 1440p Series - Multimedia Video Overlays",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoOverlayTable mdxType="VideoOverlayTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      